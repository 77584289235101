import React from "react"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { Formik } from "formik"
import * as Yup from "yup"

import firebase from "../firebase"

import Layout from "../components/layout"
import SEO from "../components/seo"

const MySwal = withReactContent(Swal)

const DemoRequestPage = () => (
  <Layout>
    <SEO title="Solicitud de demo" />
    <section className="section section-solicitud-de-demo">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-four-fifths">
            <h1 className="title has-text-centered has-text-primary is-size-2">
              ¿LISTO PARA PROBAR AIBOT EN TU NEGOCIO?
            </h1>
            <Formik
              initialValues={{
                name: "",
                phone: "",
                email: "",
                countries: "",
                company: "",
                description: "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  firebase
                    .database()
                    .ref("demo-requests")
                    .push()
                    .set(values)
                  MySwal.fire({
                    title: <p>¡Muchas gracias por tu interés!</p>,
                    html: <p>Pronto nos pondremos en contacto contigo.</p>,
                    confirmButtonText: "CONTINUAR",
                  }).then(() => {
                    window.location.reload()
                  })
                }, 500)
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required("Este campo es requerido."),
                phone: Yup.number()
                  .min(8)
                  .required("Este campo es requerido."),
                email: Yup.string()
                  .email()
                  .required("Este campo es requerido."),
                countries: Yup.string().required("Este campo es requerido."),
                company: Yup.string().required("Este campo es requerido."),
                description: Yup.string().required("Este campo es requerido."),
              })}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                } = props
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="field">
                      <label
                        htmlFor="name"
                        className="label has-text-weight-normal is-size-5 has-text-primary"
                      >
                        Nombre:
                      </label>
                      <div className="control">
                        <input
                          id="name"
                          name="name"
                          placeholder="Ej. Aibot"
                          type="text"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.name && touched.name
                              ? "input is-danger"
                              : "input"
                          }
                        />
                      </div>
                      {errors.name && touched.name && (
                        <p className="help is-danger">{errors.name}</p>
                      )}
                    </div>
                    <div className="field">
                      <label
                        htmlFor="phone"
                        className="label has-text-weight-normal is-size-5 has-text-primary"
                      >
                        Teléfono:
                      </label>
                      <div className="control">
                        <input
                          id="phone"
                          name="phone"
                          placeholder="Ej. +50254712180"
                          type="tel"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.phone && touched.phone
                              ? "input is-danger"
                              : "input"
                          }
                        />
                      </div>
                      {errors.phone && touched.phone && (
                        <p className="help is-danger">{errors.phone}</p>
                      )}
                    </div>
                    <div className="field">
                      <label
                        htmlFor="email"
                        className="label has-text-weight-normal is-size-5 has-text-primary"
                      >
                        e-Mail
                      </label>
                      <div className="control">
                        <input
                          id="email"
                          name="email"
                          placeholder="Ej. hello@helloaisolutions.com"
                          type="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.email && touched.email
                              ? "input is-danger"
                              : "input"
                          }
                        />
                      </div>
                      {errors.email && touched.email && (
                        <p className="help is-danger">{errors.email}</p>
                      )}
                    </div>
                    <div className="field">
                      <label
                        htmlFor="countries"
                        className="label has-text-weight-normal is-size-5 has-text-primary"
                      >
                        País(es) del negocio:
                      </label>
                      <div className="control">
                        <input
                          id="countries"
                          name="countries"
                          placeholder="Ej. Guatemala, USA, etc."
                          type="text"
                          value={values.countries}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.countries && touched.countries
                              ? "input is-danger"
                              : "input"
                          }
                        />
                      </div>
                      {errors.countries && touched.countries && (
                        <p className="help is-danger">{errors.countries}</p>
                      )}
                    </div>
                    <div className="field">
                      <label
                        htmlFor="company"
                        className="label has-text-weight-normal is-size-5 has-text-primary"
                      >
                        Nombre de la empresa:
                      </label>
                      <div className="control">
                        <input
                          id="company"
                          name="company"
                          placeholder="Ej. AI Solutions"
                          type="text"
                          value={values.company}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.company && touched.company
                              ? "input is-danger"
                              : "input"
                          }
                        />
                      </div>
                      {errors.company && touched.company && (
                        <p className="help is-danger">{errors.company}</p>
                      )}
                    </div>
                    <div className="field">
                      <label
                        htmlFor="description"
                        className="label has-text-weight-normal is-size-5 has-text-primary"
                      >
                        ¿A qué se dedica su empresa?:
                      </label>
                      <div className="control">
                        <textarea
                          id="description"
                          name="description"
                          type="text"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={
                            errors.description && touched.description
                              ? "textarea is-danger"
                              : "textarea"
                          }
                        ></textarea>
                      </div>
                      {errors.description && touched.description && (
                        <p className="help is-danger">{errors.description}</p>
                      )}
                    </div>
                    <div className="field is-grouped is-pulled-right">
                      <div className="control">
                        <input id="date" name="date" type="hidden" value={""} />
                        <button
                          type="submit"
                          className="button is-link is-uppercase has-text-weight-bold"
                          disabled={!dirty || isSubmitting}
                        >
                          Solicitar demo
                        </button>
                      </div>
                    </div>
                  </form>
                )
              }}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default DemoRequestPage
