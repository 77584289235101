import * as firebase from "firebase/app"
import "firebase/database"

const firebaseConfig = {
  apiKey: "AIzaSyAbauxGgMMsHL8Py3fW6YGgFSzpUrCP1HY",
  authDomain: "aibot-3bba7.firebaseapp.com",
  databaseURL: "https://aibot-3bba7.firebaseio.com",
  projectId: "aibot-3bba7",
  storageBucket: "aibot-3bba7.appspot.com",
  messagingSenderId: "443784516868",
  appId: "1:443784516868:web:4ca7f1f1166bd076",
}

firebase.initializeApp(firebaseConfig)

export default firebase
